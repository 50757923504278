import { useRef, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
  RegistrationData,
  RegistrationDataDTO,
  generateRegistrationDataDTO,
} from '../../types/device.registration.types';
import { cotService } from '../../services/cot.service';
import { useSpinner, useSpinnerDecorator } from '../../hooks/useSpinner';
import { gwService } from '../../services/gw.service';
import { RegistrationForm } from './components/RegistrationForm.component';
import * as ValidationUtils from '../../utils/validation.utils';
import { AxiosError } from 'axios';
import styles from './registrationPage.module.scss';
import { useShowPopup } from '../../hooks/useShowPopup';

export const RegistrationPage = () => {
  const { deviceId } = useParams();
  const showSpinner = useSpinner();
  const showPopup = useShowPopup();
  const initialData: RegistrationData = {
    country: '',
    serviceProvider: '',
    serviceProviderId: '',
    clinic: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    contactPerson: '',
    phone: '',
    city: '',
    purchasedFrom: '',
    state: '',
    zip: '',
  };
  const data = useRef<RegistrationData>(initialData);
  const [serialNumber, setSerialNumber] = useState<string>('');
  const [validation, setValidation] = useState<Partial<Record<keyof RegistrationData, boolean>>>({});
  const [error, setError] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);

  const isFormValid = Object.values(validation).every((isValid) => isValid);
  const submit = async () => {
    setError('');
    if (!isFormValid || !deviceId) {
      console.log('Form submission blocked due to validation errors');
      return;
    }
    const dto: RegistrationDataDTO = generateRegistrationDataDTO(data.current, deviceId);
    console.log(JSON.stringify(dto));
    try {
      await gwService.registerDevice(dto);
      showPopup('Your request has been sent.');
      setSubmitted(true);
    } catch (e) {
      setError(((e as AxiosError).response?.data as any).error || 'Error');
    }
  };
  const decorateSubmit = useSpinnerDecorator(submit);

  useEffect(() => {
    setError('');
    showSpinner(true);
    if (!deviceId) return;
    validateAllFields();
    (async () => {
      const sn = await cotService.getSerialNumberFromDeviceId(deviceId);
      setSerialNumber(sn);
      showSpinner(false);
    })();
  }, [deviceId]);

  const handleInputChange = (fieldName: keyof RegistrationData, value: string) => {
    data.current[fieldName] = value;
    validateField(fieldName, value);
  };
  const validateAllFields = (): void => {
    const fieldsToValidate: Array<keyof RegistrationData> = Object.keys(initialData) as Array<keyof RegistrationData>;
    fieldsToValidate.forEach((fieldName) => {
      validateField(fieldName, data.current[fieldName]);
    });
  };
  const validateState = (s: string): boolean => {
    return data.current.country === 'USA' ? ValidationUtils.validateNotEmptyValue(s) : true;
  };
  const validationFunctions: Partial<Record<keyof RegistrationData, (s: string) => boolean>> = {
    country: ValidationUtils.validateNotEmptyValue,
    serviceProvider: ValidationUtils.validateNotEmptyValue,
    email: ValidationUtils.validateEmail,
    phone: ValidationUtils.validatePhoneNumber,
    clinic: ValidationUtils.validateNotEmptyValue,
    addressLine1: ValidationUtils.validateNotEmptyValue,
    city: ValidationUtils.validateNotEmptyValue,
    state: validateState,
    zip: ValidationUtils.validateNotEmptyValue,
  };
  const validateField = (fieldName: keyof RegistrationData, value: string) => {
    if (validationFunctions[fieldName]) {
      const validationResult = validationFunctions[fieldName]?.(value);
      if (validationResult !== undefined)
        setValidation((prevState) => ({
          ...prevState,
          [fieldName]: validationResult,
        }));
    }
  };
  return (
    <Container className="mt-2">
      <Row>
        <Col className="setup-step-title mt-4">Register Device</Col>
      </Row>
      <RegistrationForm
        data={data}
        serialNumber={serialNumber}
        validationFunctions={validationFunctions}
        handleInputChange={handleInputChange}
      />

      <Row className="pt-4">
        <Col xs={2}>
          <Button className="filled-btn" onClick={decorateSubmit} disabled={!isFormValid || submitted}>
            Save
          </Button>
        </Col>
        <Col className="my-auto">
          <span className={styles.error_text}>{error}</span>
        </Col>
      </Row>
    </Container>
  );
};
