export type RegistrationData = {
  country: string;
  serviceProvider: string;
  serviceProviderId: string;
  clinic: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  contactPerson: string;
  phone: string;
  zip: string;
  state: string;
  city: string;
  purchasedFrom: string;
};

export type RegistrationDataDTO = {
  deviceId: string;
  serviceProvider: string;
  clinic: {
    name: string;
    addressLine1: string;
    addressLine2: string;
    country: string;
    city: string;
    state: string;
    zip: string;
    contactPersons: Array<{
      name: string;
      email: string;
      phone: string;
    }>;
  };
};

export const generateRegistrationDataDTO = (data: RegistrationData, deviceId: string): RegistrationDataDTO => {
  const transformed: RegistrationDataDTO = {
    deviceId,
    serviceProvider: data.serviceProviderId,
    clinic: {
      name: data.clinic,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      country: data.country,
      city: data.city,
      zip: data.zip,
      state: data.state,
      contactPersons: [
        {
          name: data.contactPerson,
          email: data.email,
          phone: data.phone,
        },
      ],
    },
  };

  return transformed;
};

export type CountryData = {
  id: number;
  name: string;
  codeAlpha3: string;
  companyId: string;
};
