import { useQuery } from '@tanstack/react-query';
import { cotService } from 'services/cot.service';
import { CotProject } from 'types/cot.types';
import { Project, ProjectMetadata } from 'types/cot.properties.types';
import { selectUser } from '../../store';
import { useAppSelector } from '../useApp';
import { batchProcess } from '../../utils/promisesUtils';

export const FLAT_PROJECTS_KEY = 'FLAT_PROJECTS_KEY';

const createProject = (cotProject: CotProject, metadata: ProjectMetadata): Project => {
  return { metadata, ...cotProject };
};

const getFlatProjects = async (companyId: string): Promise<Project[]> => {
  const projects = await cotService.getAllCompanyProjects(companyId);
  const uniqueCompanies = Array.from(new Set(projects.map((project) => project.company)));
  const companyProperties = await batchProcess(uniqueCompanies, (company) =>
    cotService.getCompanyProjectsProperty<ProjectMetadata>(company, 'meta.data')
  );
  const properties = companyProperties.flat();
  return projects.map((cotProject, indx) => createProject(cotProject, properties[indx]));
};

export const useGetFlatProjectsQuery = () => {
  const user = useAppSelector(selectUser);
  return useQuery(
    [FLAT_PROJECTS_KEY],
    async () => {
      return await getFlatProjects(user.company);
    },
    { enabled: !!user, staleTime: 30_000 }
  );
};
