import { MutableRefObject, useEffect, useMemo, useState } from 'react';
import { CountryData, RegistrationData } from '../../../types/device.registration.types';
import { Col, Container, Row } from 'react-bootstrap';
import ReadonlyFloatingLabelInput from '../../../components/FloatingInput/ReadonlyFloatingLabelInput.component';
import FloatingSelectInput from '../../../components/FloatingInput/FloatingSelectInput.component';
import FloatingLabelInput from '../../../components/FloatingInput/FloatingLabelInput.component';
import { usaStates } from './usa_states';
import { CotCompany } from '../../../types/cot.types';
import { cotService } from '../../../services/cot.service';
import { useSpinner } from '../../../hooks/useSpinner';
import { gwService } from '../../../services/gw.service';

interface RegistrationFormProps {
  data: MutableRefObject<RegistrationData>;
  serialNumber: string;
  validationFunctions: Partial<Record<keyof RegistrationData, (s: string) => boolean>>;
  handleInputChange: (fieldName: keyof RegistrationData, value: string) => void;
}
export const RegistrationForm = ({
  data,
  serialNumber,
  validationFunctions,
  handleInputChange,
}: RegistrationFormProps) => {
  const [country, setCountry] = useState<CountryData>();
  const [countries, setCountries] = useState<CountryData[]>();
  const [companies, setCompanies] = useState<CotCompany[]>();
  const showSpinner = useSpinner();

  useEffect(() => {
    if (countries) return;
    showSpinner(true);
    (async () => {
      let _countries = await gwService.getAvailableCountriesOptions();
      setCountries(_countries);
      showSpinner(false);
    })();
  }, []);

  useEffect(() => {
    if (!country || !countries) return;
    showSpinner(true);
    (async () => {
      let _companies = await cotService.getAllSubcompanies(country.companyId);
      _companies = _companies
        .filter((c) => c.id !== country.companyId)
        .sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      _companies.push({ name: 'Other', id: '', parent: country.companyId });
      setCompanies(_companies);
      showSpinner(false);
    })();
  }, [country]);

  const isUSA: boolean = useMemo(() => {
    return country?.codeAlpha3 === 'USA';
  }, [country]);
  return (
    <Container className="mt-2">
      <ReadonlyFloatingLabelInput label="Serial Number" className="my-1 py-1" value={serialNumber} />
      <Row>
        <Col className="mt-2" style={{ fontSize: '25px' }}>
          Clinic Info
        </Col>
      </Row>
      <FloatingSelectInput
        options={countries?.map((c) => c.name) || []}
        label="Country *"
        className="my-1 py-1"
        onChange={(value) => {
          if (!countries) return;
          handleInputChange('country', value);
          const _country = countries.find((c) => c.name === value);
          if (!_country) return;
          setCountry(_country);
          handleInputChange('state', data.current.state);
        }}
        value={data.current.country}
        validate={validationFunctions['country']}
      />
      <FloatingSelectInput
        options={companies?.map((c) => c.name) || []}
        label="Service Provider *"
        className="my-1 py-1"
        onChange={(value) => {
          if (!companies || !country) return;
          handleInputChange('serviceProvider', value);
          const indx = companies.findIndex((c) => c.name === value);
          const _serviceProviderId = indx < companies.length - 1 ? companies[indx].id : country.companyId;
          handleInputChange('serviceProviderId', _serviceProviderId);
        }}
        validate={validationFunctions['serviceProvider']}
      />
      <FloatingLabelInput
        label="Clinic Name *"
        className="my-1 py-1"
        onChange={(value) => handleInputChange('clinic', value)}
        validate={validationFunctions['clinic']}
      />
      <FloatingLabelInput
        label="Address Line 1 *"
        className="my-1 py-1"
        onChange={(value) => handleInputChange('addressLine1', value)}
        validate={validationFunctions['addressLine1']}
      />
      <FloatingLabelInput
        label="Address Line 2"
        className="my-1 py-1"
        onChange={(value) => handleInputChange('addressLine2', value)}
      />
      <FloatingLabelInput label="City *" className="my-1 py-1" onChange={(value) => handleInputChange('city', value)} />
      {isUSA && (
        <FloatingSelectInput
          options={usaStates}
          label="State *"
          className="my-1 py-1"
          onChange={(value) => handleInputChange('state', value)}
          validate={validationFunctions['state']}
        />
      )}
      <FloatingLabelInput
        label="Zip Code *"
        className="my-1 py-1"
        onChange={(value) => handleInputChange('zip', value)}
        validate={validationFunctions['zip']}
      />
      <FloatingLabelInput
        label="Email *"
        className="my-1 py-1"
        onChange={(value) => handleInputChange('email', value)}
        validate={validationFunctions['email']}
      />
      <FloatingLabelInput
        label="Contact Person"
        className="my-1 py-1"
        onChange={(value) => handleInputChange('contactPerson', value)}
      />
      <FloatingLabelInput
        label="Phone"
        className="my-1 py-1"
        onChange={(value) => handleInputChange('phone', value)}
        validate={validationFunctions['phone']}
      />
      <Row className="pt-4">
        <Col xs={12}>
          <h6 className="px-1">
            Your privacy is important to us. The information you provide is solely for Tuttnauer's use. We do not share
            it with 3rd parties. Your data is securely stored on our servers.
          </h6>
        </Col>
      </Row>
    </Container>
  );
};
