export async function batchProcess<P, T>(
  paramsArr: P[], // Array of parameters
  promiseCreator: (param: P) => Promise<T>, // Function that creates promise from a parameter
  batchSize: number = 50
): Promise<T[]> {
  const results: T[] = [];
  const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  for (let i = 0; i < paramsArr.length; i += batchSize) {
    let retries = 3;
    while (retries > 0) {
      try {
        const batch = paramsArr.slice(i, i + batchSize).map((param) => promiseCreator(param));

        const batchResults = await Promise.all(batch);
        results.push(...batchResults);

        if (i + batchSize < paramsArr.length) {
          await wait(500);
        }
        break;
      } catch (error) {
        retries--;
        if (retries === 0) {
          console.error(`Failed batch starting at index ${i} after all retries`);
          throw error;
        }
        console.log(`retrying for batch starting at index ${i}, retries=${retries}`);
        await wait(1000);
      }
    }
    if (i % 100 === 0) {
      console.log(`Processed ${Math.min(i + batchSize, paramsArr.length)}/${paramsArr.length} items`);
    }
  }

  return results;
}
