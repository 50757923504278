import { wifiList } from '../mocks/wifiList';
import { ApiMap } from '../types/request.types';

export const isDevelopment = process.env.NODE_ENV === 'development';

export const API_MAP: ApiMap = {
  //from Wifilistclient
  storeUser: {
    axiosInstance: 'base',
    url: '/loginUser',
    method: 'post',
    useMock: true,
    mock: [200],
  },
  getWifiList: {
    axiosInstance: 'base',
    url: '/api/gw/wifi',
    method: 'get',
    useMock: true,
    mock: [200, wifiList()],
  },
  connectToWifi: {
    axiosInstance: 'base',
    url: '/api/gw/wifi/connect',
    method: 'post',
    useMock: true,
    mock: [200],
  },
  wifiDisconnect: {
    axiosInstance: 'base',
    url: '/api/gw/wifi/disconnect',
    method: 'post',
    useMock: true,
    mock: [200],
  },
  getDeviceInformation: {
    axiosInstance: 'base',
    url: '/api/gw/deviceInformation',
    method: 'get',
    useMock: true,
    mock: [
      200,
      {
        apiKey: '7ccc73cbd6eb4be69a2d62378b01bc71',
        deviceId: 'fcb79508f46b47eea20a4e5314d544de',
        loggedOnUser: { email: '', apiKey: '', company: '', role: '' },
      },
    ],
  },
  getDeviceUrlsLogin: {
    axiosInstance: 'base',
    url: '/api/gw/deviceUrls/login',
    method: 'get',
    useMock: true,
    mock: [200, 'https://qa.tuttnaueriot.com/login'],
  },
  getDeviceUserAcceptance: {
    axiosInstance: 'base',
    url: '/api/gw/deviceConfig/deviceUserAcceptance',
    method: 'get',
    useMock: true,
    mock: [
      200,
      [
        {
          model: 'TEdge',
          serial: '111',
        },
        {
          model: 'TLab',
          serial: '222',
        },
        {
          model: 'TLab',
          serial: '333',
        },
        {
          model: 'TLab',
          serial: '444',
        },
        {
          model: 'TLab',
          serial: '555',
        },
      ],
    ],
  },
  //from DeviceConfigurationClient
  getAllWaitingDevices: {
    axiosInstance: 'base',
    url: '/api/gw/deviceConfig/deviceUserAcceptance',
    method: 'get',
    useMock: true,
    mock: [200],
  },
  acceptDevice: {
    axiosInstance: 'base',
    url: '/api/gw/deviceConfig/deviceUserAcceptance/accept/:serial',
    method: 'post',
    useMock: true,
    mock: [200],
  },
  getAllSerialPortMachines: {
    axiosInstance: 'base',
    url: '/api/gw/deviceConfig/deviceUserAcceptance/serialPortMachines',
    method: 'get',
    useMock: true,
    mock: [200],
  },
  getAllSerialPorts: {
    axiosInstance: 'base',
    url: '/api/gw/deviceConfig/deviceUserAcceptance/serialPorts',
    method: 'get',
    useMock: true,
    mock: [200],
  },
  setSerialPortMachines: {
    axiosInstance: 'base',
    url: '/api/gw/deviceConfig/deviceUserAcceptance/serialPortMachines',
    method: 'post',
    useMock: true,
    mock: [200],
  },
  getGwInfo: {
    axiosInstance: 'base',
    url: '/api/gw/info',
    method: 'get',
    useMock: true,
    mock: [
      200,
      [
        { title: 'Image version', value: '4c-0.1.3-16' },
        { title: 'Mac address', value: '86:47:61:ee:79:97' },
        { title: 'Version', value: 'mr:7000.2302.27.1' },
        { title: 'Wifi address', value: '192.168.50.142' },
      ],
    ],
  },
  //from COTClient
  login: {
    axiosInstance: 'cot',
    url: `/v3/login/login/`,
    method: 'post',
    useMock: false,
    mock: [200],
  },
  getUserProfile: {
    axiosInstance: 'cot',
    url: '/v3/companies/:companyId/users/profile/',
    method: 'get',
    useMock: false,
    mock: [200],
  },
  getProjectProperty: {
    axiosInstance: 'cot',
    url: `/v3/companies/:companyId/projects/:projectId/properties/?name=:propertyName`,
    method: 'get',
    useMock: false,
    mock: [200],
  },
  setProjectProperty: {
    axiosInstance: 'cot',
    url: `/v3/companies/:companyId/projects/:projectId/properties/?name=:propertyName`,
    method: 'post',
    useMock: false,
    mock: [200],
  },
  getDeviceProperty: {
    axiosInstance: 'cot',
    url: '/v3/devices/:deviceId/properties?name=:propertyName',
    method: 'get',
    useMock: false,
    mock: [200],
  },
  setDeviceProperty: {
    axiosInstance: 'cot',
    url: '/v3/devices/:deviceId/properties/?name=:propertyName',
    method: 'post',
    useMock: false,
    mock: [200],
  },
  getDeviceStream: {
    axiosInstance: 'cot',
    url: '/v4/projects/:projectId/devices/:deviceId/streams/:streamId',
    method: 'get',
    useMock: false,
    mock: [200],
  },
  setDeviceCommand: {
    axiosInstance: 'cot',
    url: '/v4/projects/:projectId/devices/:deviceId/streams/:streamId/cmd/',
    method: 'post',
    useMock: false,
    mock: [200],
  },
  getCompanyName: {
    axiosInstance: 'cot',
    url: `/v3/companies/:companyId/company_name/`,
    method: 'get',
    useMock: false,
    mock: [200],
  },
  getSubCompanies: {
    axiosInstance: 'cot',
    url: `/v3/companies/:companyId/sub_companies?page=:page`,
    method: 'get',
    useMock: false,
    mock: [200],
  },
  getAllCompanyProjects: {
    axiosInstance: 'apitutt',
    url: `/cot/companies/:companyId/all/projects`,
    method: 'get',
    useMock: false,
    mock: [200],
  },
  getAllCompanyProjectsProperty: {
    axiosInstance: 'apitutt',
    url: `/cot/companies/:companyId/projects/properties/:propertyName`,
    method: 'get',
    useMock: false,
    mock: [200],
  },
  getProject: {
    axiosInstance: 'cot',
    url: `/v3/companies/:companyId/projects/:projectId/`,
    method: 'get',
    useMock: false,
    mock: [200],
  },
  createProject: {
    axiosInstance: 'cot',
    url: `/v3/companies/:companyId/projects/`,
    method: 'post',
    useMock: false,
    mock: [200],
  },
  editProject: {
    axiosInstance: 'cot',
    url: `/v3/companies/:companyId/projects/:projectId/`,
    method: 'PUT',
    useMock: false,
    mock: [200],
  },
  allocateDevice: {
    axiosInstance: 'apitutt',
    url: `/devices/allocate/`,
    method: 'post',
    useMock: false,
    mock: [200],
  },
  deallocateDevice: {
    axiosInstance: 'apitutt',
    url: `/devices/deallocate`,
    method: 'post',
    useMock: false,
    mock: [200],
  },
  getDeviceById: {
    axiosInstance: 'cot',
    url: `/v3/devices/:deviceId/`,
    method: 'get',
    useMock: false,
    mock: [200],
  },
  getAllProjectDevices: {
    axiosInstance: 'cot',
    url: `/v3/companies/:companyId/projects/:projectId/devices/`,
    method: 'get',
    useMock: false,
    mock: [200],
  },
  getAllAssignedDevices: {
    axiosInstance: 'cot',
    url: '/api/gw/deviceConfig/deviceUserAcceptance/assignedDevices',
    method: 'get',
    useMock: true,
    mock: [200],
  },
  getGwByChildSerial: {
    axiosInstance: 'apitutt',
    url: '/devices/parentId',
    method: 'post',
    useMock: false,
    mock: [
      200,
      {
        parentDeviceId: '0a5490a8349f4102a66a787a1b4f0540',
        deviceId: '2107e3289bd945b6bfec719eca50fae5',
        model_name: 'TTA-ECO-10',
      },
    ],
  },
  getInfoFromDeviceId: {
    axiosInstance: 'apitutt',
    url: '/devices/info?deviceId=:deviceId',
    method: 'get',
    useMock: false,
    mock: [
      200,
      {
        serial_number: '9988776655',
        model_name: 'T-EDGE-10',
        className: 'Tedge',
        territory: '',
        allocation_date: '',
        company_name: '',
        company_email: '',
        settings_signature: '...184c9eae8f7d39f',
        sys_checksum: '...def95567abf601d',
        cyc_checksum: '...207bb5ca4a465be',
      },
    ],
  },
  getRegistrationRegions: {
    axiosInstance: 'apitutt',
    url: '/devices/registration/regions',
    method: 'get',
    useMock: false,
    mock: [200, ['USA', 'UK', 'Europe', 'Other']],
  },
  getAllCountries: {
    axiosInstance: 'apitutt',
    url: '/data/countries',
    method: 'get',
    useMock: false,
    mock: [200, []],
  },
  getAllSubcompanies: {
    axiosInstance: 'apitutt',
    url: '/cot/companies/:companyId/all',
    method: 'get',
    useMock: false,
    mock: [200, []],
  },
  registerDevice: {
    axiosInstance: 'apitutt',
    url: '/devices/registration',
    method: 'post',
    useMock: false,
    mock: [
      200,
      {
        id: '9dcbf850-2cc5-4706-9387-cacf6c45502a',
        serialNumber: '9988776655',
        deviceId: '2fa91a7bbcea4bea90a47bc72cb4cd56',
        serviceProvider: 'aa',
        createdAt: '2023-12-10T15:08:37.403Z',
        updatedAt: '2023-12-10T15:08:37.403Z',
        isHandled: false,
        isDeleted: false,
        clinicId: '626e4729-944b-4df1-9209-fb6b5bab4519',
      },
    ],
  },
};
