import { WifiDetails } from 'components/WifiSelectModal/model/WifiDetails';
import { request, API_MAP } from './httpService';
import { DeviceInformation, GwInfoItem } from 'types/gw.types';
import { CountryData, RegistrationDataDTO } from '../types/device.registration.types';

class GwService {
  public getWifiList = async (): Promise<WifiDetails[]> => {
    return await request(API_MAP.getWifiList);
  };

  public wifiDisconnect = async (): Promise<void> => {
    await request(API_MAP.wifiDisconnect);
  };

  public getDeviceInformation = async (): Promise<DeviceInformation> => {
    return await request(API_MAP.getDeviceInformation);
  };

  public getGwInfo = async (): Promise<GwInfoItem[]> => {
    return await request(API_MAP.getGwInfo);
  };

  public getDeviceUrlsLogin = async (): Promise<String> => {
    return await request(API_MAP.getDeviceUrlsLogin);
  };
  public getAvailableTerrytoryOptions = async (): Promise<string[]> => {
    return await request(API_MAP.getRegistrationRegions);
  };
  public getAvailableCountriesOptions = async (): Promise<CountryData[]> => {
    return await request(API_MAP.getAllCountries);
  };
  public registerDevice = async (dto: RegistrationDataDTO): Promise<void> => {
    await request(API_MAP.registerDevice, {
      payload: dto,
    });
  };
}

export const gwService = new GwService();
